<template>
  <div class="vs-row">
    <vx-card actionable class="cardx" title="Purchases & Receipts">
      <div class="gap">
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="11"
          >
            <p style="margin-right: 1%">Can-ID</p>
            <vs-input v-model="canid" />
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="search"
              @click="openAddReceipt()"
              style="margin-left: 1%"
            ></vs-button>
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="refresh"
              @click="refreshReceipts()"
              style="margin-left: 1%"
            ></vs-button>
          </vs-col>
        </vs-row>
      </div>
      <div class="gap">
        <vs-table :data="receipts" id="dummy" v-if="visible">
          <template slot="thead" class="exc">
            <vs-th>Can-ID</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Type</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Agreed Cost</vs-th>
            <vs-th>Paid</vs-th>
            <vs-th>Pending</vs-th>
            <vs-th v-if="!getrole('cm1')">International Purchases</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :state="getdefaulterColor(item)"
              :key="item.spoc_name"
              v-for="item in data"
            >
              <vs-td :data="item.spoc_name">
                <div>{{ item.identity }}</div>
              </vs-td>

              <vs-td :data="item.call_utilization">
                <div>{{ item.person_name }}</div>
              </vs-td>

              <vs-td :data="item.call_utilization">
                <div>{{ item.type }}</div>
              </vs-td>

              <vs-td :data="item.call_utilization">
                <div>{{ item.name }}</div>
              </vs-td>

              <vs-td :data="item.untracked_in_calls_count">
                <div>{{ item.agreed_cost }}</div>
              </vs-td>

              <vs-td :data="item.missed_call_count">
                <div>{{ sumOfAmount(item.receipts) }}</div>
              </vs-td>

              <vs-td :data="item.missed_call_count">
                <div>
                  {{ item.agreed_cost - sumOfAmount(item.receipts) }}
                </div>
              </vs-td>

              <vs-td :data="item" v-if="!getrole('cm1')">
                <div>
                  <vs-row>
                    <vs-button
                      v-if="item.international == 0"
                      color="primary"
                      type="gradient"
                      icon="public"
                      @click="internationalPurchasePopup(item.id)"
                    ></vs-button>
                    <vs-button
                      v-if="item.international == 1"
                      color="success"
                      type="gradient"
                      icon="public"
                      @click="disableInternationalPurchase(item.id)"
                    ></vs-button>
                  </vs-row>
                </div>
              </vs-td>

              <template class="expand-user" slot="expand">
                <vs-row>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="12"
                  >
                    <div style="width: 100%">
                      <vs-table :data="item.receipts">
                        <template slot="thead">
                          <vs-th>Can-ID</vs-th>
                          <vs-th>Person Name</vs-th>
                          <vs-th>Transaction Type</vs-th>
                          <vs-th>Cheque No</vs-th>
                          <vs-th>PDC Date</vs-th>
                          <vs-th>Transaction Id</vs-th>
                          <vs-th>TID</vs-th>
                          <vs-th>City</vs-th>
                          <vs-th>Receipt No</vs-th>
                          <vs-th>Amount</vs-th>
                          <vs-th>Receipt Date</vs-th>
                          <vs-th
                            v-if="
                              getUserId(132) ||
                              getUserId(139) ||
                              getUserId(927) ||
                              getUserId(1299)
                            "
                            >Deposit Date</vs-th
                          >
                          <vs-th
                            v-if="
                              getUserId(132) ||
                              getUserId(139) ||
                              getUserId(927) ||
                              getUserId(39) ||
                              getUserId(1299)
                            "
                            >MR Number</vs-th
                          >
                        </template>

                        <template slot-scope="{ data }">
                          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>{{ tr.identity }}</vs-td>
                            <vs-td>{{ tr.person_name }}</vs-td>
                            <vs-td>{{ tr.transaction_type }}</vs-td>
                            <vs-td>{{ tr.cheque_no }}</vs-td>
                            <vs-td>{{ tr.pde_date }}</vs-td>
                            <vs-td>{{ tr.transaction_id }}</vs-td>
                            <vs-td>{{ tr.cc_machine_tid_no }}</vs-td>
                            <vs-td>{{ tr.city }}</vs-td>
                            <vs-td>{{ tr.receipt_no }}</vs-td>
                            <vs-td>{{ tr.amount }}</vs-td>
                            <vs-td>{{
                              unixTimestampConvert(tr.receipt_date)
                            }}</vs-td>
                            <vs-td
                              v-if="
                                getUserId(132) ||
                                getUserId(139) ||
                                getUserId(927) ||
                                getUserId(1299)
                              "
                              >{{
                                unixTimestampConvert(tr.deposit_date)
                              }}</vs-td
                            >
                            <vs-td
                              v-if="
                                getUserId(132) ||
                                getUserId(139) ||
                                getUserId(927) ||
                                getUserId(39) ||
                                getUserId(1299)
                              "
                              >{{ tr.mr_number }}</vs-td
                            >
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                  </vs-col>
                </vs-row>
              </template>
            </vs-tr>
            <!-- </div> -->
          </template>
        </vs-table>
        <vs-popup
          class="holamundo"
          title="Are you sure?"
          :active.sync="popupOpen"
        >
          <vs-row style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <b>{{ text }}</b>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
            >
              <vs-button color="danger" type="border" @click="popupOpen = false"
                >Cancel</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-popup>
      </div>
      <vs-row style="margin-top: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="12"
        >
          <vs-pagination
            :total="tablelinks"
            v-model="currentpage"
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
    <vs-popup title="International Purchase" :active.sync="open_international_purchase_popup">
      <vs-row style="margin-bottom: 3%">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <p>Are you sure, you want to mark this purchase international?</p>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button style="margin-right: 5px;" color="danger" @click="open_international_purchase_popup = false">No</vs-button>
          <vs-button color="success"  @click="enableinternationalStatus">Yes</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup title="International Purchase" :active.sync="open_disable_international_popup">
      <vs-row style="margin-bottom: 3%">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <p>Are you sure, you want to make this purchase as not international?</p>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button style="margin-right: 5px;" color="danger"  @click="open_disable_international_popup = false">No</vs-button>
          <vs-button color="success"  @click="disableinternationalStatus">Yes</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import eventbus from "../components/eventbus.js"
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
export default {
  mounted() {
    this.getSpoc();
    eventbus.$on("reload-receipts", () => {
      this.getReceipts();
    });
  },
  components: {
    vSelect,
  },
  data() {
    return {
      is_international: null,
      international_purchase_id : null,
      open_disable_international_popup: false,
      open_international_purchase_popup: false,
      delete_receipt_popup: false,
      serchidentity: "",
      transferAmount: "",
      mwb: {},
      emails: [],
      mobiles: [],
      transfer_receipt_details: false,
      transfer_receipt_popup: false,
      identity: "",
      agreed_fees: "",
      course: "",
      select1: 3,
      options1: [
        { text: "IT", value: 0 },
        { text: "Blade Runner", value: 2 },
        { text: "Thor Ragnarok", value: 3 },
      ],
      value1: "",
      value2: "",
      confirm_agreed_fee: false,
      agreed_fee_popup: false,
      logged_in_user_id: "",
      splittooltip: "Testing",
      misceliniousdata: "",
      miscelinouspopup: false,
      splitreceiptpopup: false,
      visible: false,
      receipts: [],
      currentpage: 1,
      tablelinks: 1,
      canid: "",
      city: "",
      cities: [],
      registeredSpoc: "",
      identityCityCode: "",
      activeConfirm: false,
      selectedReceiptForSplit: null,
      splitdata: {
        person_name: "",
        transaction_type: "",
        transaction_id: "",
        amount: "",
        receipt_date: "",
        added_by_name: "",
      },
      purchase_type: "",
      purchase_types: ["Miscellaneous", "Additional Purchase"],
      purchase_amount: null,
      TotalLeadData: "",
      Transfercourse: "",
      delete_receipt_data: {},
      Transactiontypes: [
        "course",
        "Additional Purchase",
        "Miscellaneous Purchase",
      ],
      transaction_type: "",
      deletepurchasedata: "",
      deletepurchasepopup: false,
      text: "",
      defaulterPayload: "",
      popupOpen: false,
    };
  },
  watch: {
    currentpage() {
      console.log(this.canid);

      if (this.canid === null || this.canid === "") {
        this.getReceipts();
        console.log("can  iud");
      } else if (this.canid !== null || this.canid !== "") {
        this.getReceipts(this.canid);
        console.log("can non iud");
      }
    },
    city: function (val) {
      this.identityCityCode = val.substring(0, 1).toUpperCase();
    },
  },
  methods: {
    disableinternationalStatus(){
      this.is_international = 0;
      this.markPuchaseAsInternational();
    },
    enableinternationalStatus(){
      this.is_international = 1;
      this.markPuchaseAsInternational();
    },
    disableInternationalPurchase(purchase_id){
      this.open_disable_international_popup = true;
       this.international_purchase_id = purchase_id;
    },
    internationalPurchasePopup(purchase_id) {
      console.log("internationalPurchasePopup",purchase_id);
      this.open_international_purchase_popup = true;
      this.international_purchase_id = purchase_id;
      // console.log("purchseId",purchseId);
    },
    markPuchaseAsInternational(){
      let obj = {
        is_international: this.is_international,
        purchase_id: this.international_purchase_id ,
      }; 
      console.log(obj,"is_international");
      let url = `${constants.SERVER_API}markPuchaseAsInternational`
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("markPuchaseAsInternational",response);
          if(response.data.status == "success"){
            this.$vs.notify({
              title: response.data.message,
              color: "success",
            });
            this.getReceipts();
          }
          if(response.data.status == "error"){
            this.$vs.notify({
              title: response.data.message[0],
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
      this.open_disable_international_popup = false;
      this.open_international_purchase_popup = false;
    },
    
    getdefaulterColor(fetch) {
      console.log("fetch", fetch.defaulter);

      switch (fetch.defaulter) {
        case 0:
          return "";
        case 1:
          return "danger";
      }
    },
    sumOfAmount(data) {
      // console.log("amount", data)
      var msgTotal = data.reduce(function (prev, cur) {
        return prev + cur.amount;
      }, 0);
      // console.log('Total Messages:', msgTotal);
      return msgTotal;
    },
    clear() {
      this.transferAmount = "";
      this.serchidentity = "";
      this.mwb = {};
      this.Transfercourse = "";
    },
    transferreceiptpopup(item) {
      this.clear();
      console.log("item", item);
      this.transfer_receipt_details = false;
      this.TotalLeadData = item;
      this.transferAmount = this.TotalLeadData.amount;
      this.transfer_receipt_popup = true;
    },
    transferreceiptdetails() {
      this.$vs.loading();
      let obj = {
        identity: this.serchidentity,
        type: "identity",
      };
      this.emails = [];
      this.mobiles = [];
      if (obj.identity !== "") {
        axios
          .get(`${constants.SERVER_API}getLeadDetailsForChangeLeadToDND`, {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            if (response.data.message !== "Not Authorised to change details") {
              if (response.data.response !== "No Records found") {
                let emails = response.data.response.email;
                let mobiles = response.data.response.mobile;
                this.mwb = response.data.response.mwb;
                this.transfer_receipt_details = true;
                console.log("response emails", emails);
                console.log("response mobiles", mobiles);
                console.log("response mwb", this.mwb);
                emails.forEach((email) => {
                  this.emails.push(email.email);
                });
                mobiles.forEach((mobile) => {
                  this.mobiles.push(mobile.last_ten_digits);
                });
                console.log("response emails", this.emails);
                console.log("response mobiles", this.mobiles);
                this.dndData = true;
              } else {
                this.$vs.notify({
                  title: response.data.response,
                  color: "warning",
                });
              }
            } else {
              this.$vs.notify({
                title: response.data.message,
                color: "warning",
              });
            }
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        this.$vs.notify({
          title: "Please Enter Can-ID",
          color: "danger",
        });
      }
    },
    refreshReceipts() {
      this.canid = "";
      this.city = "";
      this.currentpage = 1;
      this.getReceipts();
    },
    getrole(providedrole) {
      if (localStorage.getItem("role") === providedrole) {
        return true;
      } else {
        return false;
      }
    },
    addenrollment(item) {
      console.log("emitted", item.receipts.length);
      eventbus.$emit("open-AddEnrollment", item);
    },
    openAddReceipt() {
      // alert(this.canid);
      console.log("can id --- open", this.canid);
      this.getReceipts();
    },
    getSpoc() {
      let url = null;
      if (this.getrole("exed2")) {
        url = `${constants.SERVER_API}getExed`;
      } else {
        url = `${constants.SERVER_API}getSpocs`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.logged_in_user_id = response.data.logged_in_user_id;
          this.registeredSpoc = response.data.logged_in_user_id;
          response.data.spocs.forEach((spoc) => {
            if (spoc.id === this.registeredSpoc) {
              // console.log(spoc, "found the spoc");
              this.identityCityCode = spoc.city.charAt(0);
              // console.log(this.identityCityCode);
            }
          });
          this.extractSpocByCity(response.data.spocs);
          this.getReceipts();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getUserId(userID) {
      if (this.logged_in_user_id === userID) {
        return true;
      } else {
        return false;
      }
    },
    extractSpocByCity(response) {
      // console.log(response);
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let unoderedCities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          unoderedCities.push(key);
        }
      }
      this.cities = this.sortArrayAlphabetically(unoderedCities);
      // this.city = this.cities[0];
      // this.getEnrollmentstoApprove();
    },
    getReceipts() {
      this.$vs.loading();
      this.visible = false;
      let idstring = "";
      let pagestring = "";
      let url = "";
      console.log("can id1----", this.canid);
      if (this.canid != "") {
        pagestring = `page=${this.currentpage}`;
        // idstring = `identity=${this.identityCityCode}-${canid}`;
        console.log("can id2----", this.canid);
        idstring = `identity=${this.canid}`;

        url = `${constants.SERVER_API}getAllPurchasesForReceipts?${pagestring}&${idstring}`;
      } else {
        console.log("can id3----", this.canid);
        pagestring = `page=${this.currentpage}`;
        url = `${constants.SERVER_API}getAllPurchasesForReceipts?${pagestring}`;
      }
      // let url = `${constants.SERVER_API}getAllPurchasesForReceipts?${pagestring}&${idstring}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("purchase id receipts", response);
          this.$vs.loading.close();
          if (response.data.status !== "success") {
            this.handleNotification(response);
          } else {
            this.tablelinks = response.data.data.last_page;
            this.receipts = response.data.data.data;
            this.visible = true;
          }
          //   this.amount_paid = response.data.amount_paid;
          //   this.pending_amount = response.data.amount_pending;
          //   this.tablelinks = response.data.last_page;
          //   this.visible = true;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
.odd {
  background-color: red;
}

.even {
  background-color: green;
}
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}
/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}
/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}
/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}
/* Directions */
/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}
[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}
/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}
/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}
.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}
.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}
</style>